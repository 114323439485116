<template>
  <div
    class="container p0"
    v-title
    id="mapView"
    :data-title="$t('i18n.mapView')"
  >
    <el-input
      autocomplete="off"
      :placeholder="$t('i18n.search')"
      v-model="search"
      @keyup.enter="locateRescueElevator"
    >
      <template #append>
        <el-button
          icon="el-icon-search"
          @click="locateRescueElevator"
        ></el-button>
      </template>
    </el-input>
    <!-- <el-tabs
      v-loading="loading"
      type="border-card"
      v-model="activeName"
      @tab-click="handleCurrentChange(1)"
    >
      <el-tab-pane label="全部" name="0"></el-tab-pane>
      <el-tab-pane label="处理中" name="20">
        <template v-for="(ele, index) in elevatorData" :key="index">
          <div class="list">
            <div
              v-for="(ele, index) in elevatorData"
              :key="index"
              @click="selectElevator(ele, index)"
              :class="{ active: activeEleIndex === index }"
            >
              <span class="index">
                {{ index + 1 + (paging.pageNumber - 1) * 10 }}
              </span>
              <div class="item">
                <div>{{ $t("i18n.orderNumber") }}：{{ ele.billNumber }}</div>
                <div>{{ $t("i18n.workOrderStatus") }}：{{ ele.status }}</div>
                <div>{{ $t("i18n.handler") }}：{{ ele.actor }}</div>
                <div>
                  {{ $t("i18n.elevatorNo") }}：
                  <a href="javascript:void(0);" @click="enterDetail(ele.id)">
                    {{ ele.elevatorNumber }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center" v-show="total > 0">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane label="未处理" name="10">
        <div class="list">
          <div
            v-for="(ele, index) in elevatorData"
            :key="index"
            @click="selectElevator(ele, index)"
            :class="{ active: activeEleIndex === index }"
          >
            <span class="index">
              {{ index + 1 + (paging.pageNumber - 1) * 10 }}
            </span>
            <div class="item">
              <div>{{ $t("i18n.orderNumber") }}：{{ ele.billNumber }}</div>
              <div>{{ $t("i18n.workOrderStatus") }}：{{ ele.status }}</div>
              <div>{{ $t("i18n.handler") }}：{{ ele.actor }}</div>
              <div>
                {{ $t("i18n.elevatorNo") }}：
                <a
                  href="javascript:void(0);"
                  @click="enterDetail(ele.elevatorId)"
                >
                  {{ ele.elevatorNumber }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" v-show="total > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs> -->
    <div id="jlMap" :style="{ height: $defined.HEIGHT - 140 + 'px' }"></div>
    <div class="card-container" v-show="personnel.length > 0">
      <el-card class="box-card">
        <template #header>
          <span>{{ $t("i18n.maintenancePerson") }}</span>
        </template>
        <div
          v-for="(item, index) in personnel"
          :key="index"
          class="text item"
          @click="setMaintenancePerson(item)"
        >
          {{ index + 1 }}<span>{{ item.name }}</span>
          <span v-if="item.level">
            {{ $t("i18n.level") }}：{{ $t("i18n." + item.level) }}
          </span>
          <span v-else>{{ $t("i18n.level") }}：{{ $t("i18n.none") }}</span>
        </div>
      </el-card>
    </div>
  </div>
  <el-dialog
    :title="$t('i18n.choosePerson')"
    v-model="dialogFormVisible"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <jl-form
      :columns="columns"
      @onSubmit="onSubmit"
      @resetForm="resetForm"
    ></jl-form>
  </el-dialog>
  <el-dialog
    :title="$t('i18n.sendTrappedOrder')"
    v-model="dialogOrderVisible"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <jl-form
      :columns="orderColumns"
      @onSubmit="sendToMaintenance"
      @resetForm="resetForm"
    ></jl-form>
  </el-dialog>
</template>
<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import BMapGL from "BMap";
import jlForm from "../../components/form";
import eleImg from "../../assets/pointMap/end.png";
import manImg from "../../assets/pointMap/start.png";
import gridImg from "../../assets/pointMap/ele.png";
import carImg from "../../assets/pointMap/car.png";
import clusterImg from "../../assets/pointMap/remark.png";
import api from "../../utils/api";
export default {
  name: "GridRescue",
  components: {
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      jlMap: "",
      activeName: "0",
      elevatorData: [],
      total: 0,
      zoom: 5,
      loading: false,
      paging: { pageNumber: 1, pageSize: 100 },
      search: "",
      style: proxy.$config.labelStyle2,
      activeEleIndex: "",
      personnel: [],
      dialogFormVisible: false,
      columns: [
        {
          prop: "otherStaff",
          label: "otherStaff",
          type: "select",
          data: [],
          props: { label: "name", value: "name" },
        },
      ],
      dialogOrderVisible: false,
      orderColumns: [
        {
          value: t("i18n.emergencyRescue"),
          readonly: true,
          label: "faultLevel",
          prop: "faultLevel",
        },
        { readonly: true, label: "elevatorNo", prop: "elevatorNo" },
        { readonly: true, label: "address", prop: "address" },
        { readonly: true, label: "elevatorType", prop: "elevatorType" },
        { prop: "faultPerson", label: "serviceStaff", type: "input" },
        {
          prop: "faultPersonTelephone",
          label: "contractInformation",
          type: "input",
        },
        {
          prop: "faultDescription",
          label: "faultDescription",
          type: "input",
        },
        {
          readonly: true,
          label: "maintenancePerson",
          prop: "maintenancePerson",
        },
        {
          readonly: true,
          label: "contractInformation",
          prop: "contractInformation",
        },
      ],
      orderData: {},
      squareArea: {},
    });

    const initMap = () => {
      var map = (state.jlMap = new BMapGL.Map("jlMap"));
      map.centerAndZoom(new BMapGL.Point(proxy.$point[0], proxy.$point[1]), 5);
      map.enableScrollWheelZoom(true);
      map.enableDragging();
      map.setMapStyleV2({ styleId: "65801312203374f2da4af504c50cfedc" });
      //
      map.addEventListener('zoomend', function(e) {
        state.zoom= map.getZoom(); // 获取缩放级别
        console.log(state.zoom);
        var bs = map.getBounds();   //获取可视区域
        var bssw = bs.getSouthWest();   //可视区域左下角
        var bsne = bs.getNorthEast();   //可视区域右上角
        console.log("当前地图可视范围是：" + bssw.lng + "," + bssw.lat + "到" + bsne.lng + "," + bsne.lat);
        // loadEngineers(22);
        let type =5;
        if(state.zoom <=5){
          type = 1;
        }
        else if(state.zoom == 6){
          type = 2;
        }
        else if(state.zoom <= 8){
          type = 3;
        }
        else if(state.zoom <= 13){
          type = 4;
        }
        else{
          type = 5;
        }
        loadRescueBills(type, bssw.lng, bssw.lat, bsne.lng, bsne.lat);
      });
      map.addEventListener('dragend', function(e) {
        state.zoom= map.getZoom(); // 获取缩放级别
        console.log(state.zoom);
        var bs = map.getBounds();   //获取可视区域
        var bssw = bs.getSouthWest();   //可视区域左下角
        var bsne = bs.getNorthEast();   //可视区域右上角
        console.log("当前地图可视范围是：" + bssw.lng + "," + bssw.lat + "到" + bsne.lng + "," + bsne.lat);
        // loadEngineers(22);
        let type =5;
        if(state.zoom <=5){
          type = 1;
        }
        else if(state.zoom == 6){
          type = 2;
        }
        else if(state.zoom <= 8){
          type = 3;
        }
        else if(state.zoom <= 13){
          type = 4;
        }
        else{
          type = 5;
        }
        loadRescueBills(type, bssw.lng, bssw.lat, bsne.lng, bsne.lat);
      });
      //添加地图平移缩放控件
      state.jlMap.addControl(new   BMapGL.NavigationControl());
    };

    const initData = () => {
      loadRescueBills(1, 74.24312, 18.014734, 131.973837, 53.686846);
    };

    const handleCurrentChange = (val) => {
      if (val) {
        state.paging.pageNumber = val;
        loadRescueBills(state.activeName);
      }
    };

    const loadRescueBills = async (type, lng1,lat1,lng2,lat2) => {
      state.loading = true;
      state.paging.status = type;
      let params = {
        "leftBottomLat": lat1,
        "leftBottomLong": lng1,
        "rightTopLat": lat2,
        "rightTopLong": lng2,
        "tier": type
      }
      
      
      
      // state.total = data.total;
      switch(true){
        case type <= 4 :
          const { data } = await proxy.$api.monitor.getArea(params);
          state.elevatorData = data;
          break;
        default:
            const {data:elevator} = await proxy.$api.monitor.getElevatorByArea(params)
            state.elevatorData = elevator.map(item=>{
              return {"id":item.id,"gpsLat":item.lat,"gpsLng":item.lng,"number":item.number};
            });
            const {data:menData} = await proxy.$api.monitor.getMan(params);
            const men = menData && menData.map(item=>{
              return {"id":item.id,"gpsLat":item.lat,"gpsLng":item.lng,"manNumber":item.name};
            });
            if(men){
              state.elevatorData = state.elevatorData.concat(men);
            }
            
            // console.log(state.elevatorData);
            break;

      }
      
      state.loading = false;
      console.log(type, state.elevatorData);
      addPointIcon(state.elevatorData);
    };
    const loadEngineers = async (type) => {
      state.loading = true;
      state.paging.status = type;
      // let params = {
        
      // }
      const { data } = await proxy.$api.monitor.eleNotError(state.paging);
      
      state.elevatorData = [
        {
          "all": 16023,
          "check": 0,
          "fault": 0,
          "gpsLat": "35.86166",
          "gpsLng": "104.195397",
          "gpsLong": "104.195397",
          "imgUrl": "https://resource.dataserver.cn/lib/base/images/remark.png",
          "name": "中国",
          "normal": 10,
          "offline": 16013,
          "online": 10
        },
      ];
      // state.total = data.total;
      switch(true){
        case type <= 5 :
          state.elevatorData = [
          {
            "all": 16023,
            "check": 0,
            "fault": 0,
            "gpsLat": "35.86166",
            "gpsLng": "104.195397",
            "gpsLong": "104.195397",
            "imgUrl": "https://resource.dataserver.cn/lib/base/images/remark.png",
            "name": "中国",
            "normal": 10,
            "offline": 16013,
            "online": 10
          },
          ];
          break;
          case type == 6 :
            
          break;
          
          case type == 10:
            
            break;
          default:
            
            state.elevatorData = data.records.map(item=>{
              return {"id":item.id,"gpsLat":item.lat,"gpsLng":item.lng,"manNumber":item.id};
            });
            break;

      }
      
      state.loading = false;
      addPointIcon(state.elevatorData);
    };

    const selectElevator = (pt, index) => {
      if (index == 3) {
        // 单个电梯点击事件
        state.activeEleIndex = index;
        state.jlMap.clearOverlays();
      }
      //区域坐标
      else if(index == -1){
        var elePosition = new BMapGL.Point(pt.gpsLng, pt.gpsLat);
        var label = pt.name + " " + pt.total;
        label = "<span class='name'>"+pt.name+"</span>" + "<span class='name'>"+pt.total+"</span>"
        var icon = createIcon(pt);
        drawPoint(elePosition, icon, label);
        if (pt.manLat && pt.manLng) {
          var manPosition = new BMapGL.Point(pt.manLng, pt.manLat);
          var label1 = pt.manNumber + "/" + pt.actor + "/" + pt.manPhone;
          drawPoint(manPosition, manImg, label1);
          var polyline = new BMapGL.Polyline([elePosition, manPosition], {
            strokeColor: "#E9212A",
            strokeStyle: "solid",
            strokeWeight: 1,
            strokeOpacity: 0.8,
          });
          state.jlMap.addOverlay(polyline);
        }
      }
      //维修人员
      else if(index == 2){
        if (pt.gpsLat && pt.gpsLng) {
          var manPosition = new BMapGL.Point(pt.gpsLng, pt.gpsLat);
          // var label1 = pt.manNumber + "/" + pt.actor + "/" + pt.manPhone;
          drawMen(manPosition, "", pt);
          // var polyline = new BMapGL.Polyline([elePosition, manPosition], {
          //   strokeColor: "#E9212A",
          //   strokeStyle: "solid",
          //   strokeWeight: 1,
          //   strokeOpacity: 0.8,
          // });
          // state.jlMap.addOverlay(polyline);
        }
      }
      else{
        //电梯
        var elePosition = new BMapGL.Point(pt.gpsLng, pt.gpsLat);
        // var label = pt.name + " " + pt.all;
        // label = "<span class='name'>"+pt.name+"</span>" + "<span class='name'>"+pt.all+"</span>"
        var icon = createIcon(pt);
        drawElevator(elePosition, "", pt);
        // if (pt.manLat && pt.manLng) {
        //   var manPosition = new BMapGL.Point(pt.manLng, pt.manLat);
        //   var label1 = pt.manNumber + "/" + pt.actor + "/" + pt.manPhone;
        //   drawPoint(manPosition, manImg, label1);
        //   var polyline = new BMapGL.Polyline([elePosition, manPosition], {
        //     strokeColor: "#E9212A",
        //     strokeStyle: "solid",
        //     strokeWeight: 1,
        //     strokeOpacity: 0.8,
        //   });
        //   state.jlMap.addOverlay(polyline);
        // }
      }
      
    };

    const addPointIcon = (data) => {
      state.jlMap.clearOverlays();
      var points = [];
      for (let i = 0; i < data.length; i++) {
        var pt = data[i];
        // console.log(pt);
        //无坐标的跳过
        if(!pt.gpsLng)continue;
        if(pt.name){
          //无数量的区域也跳过
          if(pt.total==0)continue;
          selectElevator(pt, -1);
        }else if(pt.manNumber){
          selectElevator(pt, 2);
        }else{
          selectElevator(pt, 4);
        }
        
        points.push({ lng: pt.gpsLng, lat: pt.gpsLat });
        if (pt.manLat && pt.manLng) {
          points.push({ lng: pt.manLng, lat: pt.manLat });
        }
      }
      // 自动调整地图让标注显示在最佳视野内
      // state.jlMap.setViewport(points);
    };

    const drawPoint = (position, icon, str ) => {

      //var icon = new BMapGL.Icon("https://resource.dataserver.cn/lib/base/images/remark.png", new BMapGL.Size(65, 65));
      var marker = new BMapGL.Marker(position, { icon: icon });
      var label = new BMapGL.Label(str, {
        offset: new BMapGL.Size(12, 16),
      });
      
      label.setStyle(state.style);

      marker.setLabel(label);
       //添加点击事件
       marker.addEventListener("dblclick", function () {
          console.log("我是双击@")
          state.jlMap.centerAndZoom(position,state.zoom+1);
        });
 
      state.jlMap.addOverlay(marker);
    };

    const drawElevator = (position, icon, str ) => {
      console.log(str);
      var icon = new BMapGL.Icon("https://resource.dataserver.cn/lib/base/images/point_gray.png", new BMapGL.Size(45, 45));
      var marker = new BMapGL.Marker(position, {icon: icon});
      //添加点击事件
      marker.addEventListener("click", function () {
        var opts = {
              width: 200,
              height: 100,
              title:
                t("i18n.elevatorNo") +
                "：<a class='eleNumber' data-sid=" +
                str.id +
                ">" +
                str.number +
                "</a>",
            };
            var infoWindow = new BMapGL.InfoWindow(
              "",
              opts
            );
            // var pt = new BMapGL.Point(info.lng, info.lat);
            state.jlMap.openInfoWindow(infoWindow, position);
            if (!infoWindow.isOpen()) {
              //如果没有打开，则监听打开事件，获取按钮，添加事件
              infoWindow.addEventListener("open", function () {
                infoWindowOnClick();
              });
            } else {
              //如果已经打开，直接获取按钮，添加事件
              infoWindowOnClick();
            }
        });

      state.jlMap.addOverlay(marker);
    };

    const drawMen = (position, icon, str ) => {
      console.log(str);
      var icon = new BMapGL.Icon("https://iot.joylive.com:7443/files/juli/show?filePath=map/man5.png", new BMapGL.Size(32, 45));
      var marker = new BMapGL.Marker(position, 
      {
        icon: icon
      }
     );

      
      state.jlMap.addOverlay(marker);
      marker.addEventListener("click", function () {
        var opts = {
              width: 200,
              height: 100,
              title:
                t("i18n.maintenancePerson") +
                "：<a class='eleNumber' data-sid=" +
                str.id +
                ">" +
                str.manNumber +
                "</a>",
            };
            var infoWindow = new BMapGL.InfoWindow(
              "",
              opts
            );
            // var pt = new BMapGL.Point(info.lng, info.lat);
            state.jlMap.openInfoWindow(infoWindow, position);
            if (!infoWindow.isOpen()) {
              //如果没有打开，则监听打开事件，获取按钮，添加事件
              infoWindow.addEventListener("open", function () {
                infoWindowOnClick();
              });
            } else {
              //如果已经打开，直接获取按钮，添加事件
              infoWindowOnClick();
            }
        });
      };

    //创建一个Icon
    const createIcon = (json)=>{
      // console.log(json);
      var myIcon = new BMapGL.Icon("https://resource.dataserver.cn/lib/base/images/remark.png", new BMapGL.Size(65, 65), {   
          // 指定定位位置。  
          // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
          // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
          // 图标中央下端的尖角位置。   
          anchor: new BMapGL.Size(10, 25),   
          // 设置图片偏移。  
          // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
          // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
          imageOffset: new BMapGL.Size(0, -171)   // 设置图片偏移   
      });  
      // var icon = new BMapGL.Icon(
      //   json.imgUrl,
      //   new BMapGL.Size(65,65),
      //   {
      //     imageOffset: new BMapGL.Size(0,0),
      //     infoWindowOffset: new BMapGL.Size(100,1),
      //     offset: new BMapGL.Size(0,-171),
      //   });

        return myIcon;

    }

    const enterDetail = (id) => {
      let routeData = router.resolve({
        path: "/realTime/detail",
        query: { id: id },
      });
      window.open(routeData.href, "_blank");
    };

    const locateRescueElevator = async () => {
      state.jlMap.clearOverlays();
      const { data } = await proxy.$api.rescue.cueDiscovery({
        number: state.search,
      });
      var myIcon = new BMapGL.Icon(gridImg, new BMapGL.Size(36, 36));
      setEleData(data[0]); // 默认设置第一台电梯
      data.map((ele) => {
        var marker = new BMapGL.Marker(new BMapGL.Point(ele.lng, ele.lat), {
          icon: myIcon,
        });
        state.jlMap.addOverlay(marker);
        // 跳动的动画
        marker.setAnimation("BMAP_ANIMATION_BOUNCE");
        var _iw = createInfoWindow(ele);
        marker.addEventListener("click", function () {
          state.jlMap.openInfoWindow(_iw);
          setEleData(ele);
        });
      });
      state.jlMap.setViewport(data);
    };

    const locatePoint = async() => {
      state.jlMap.clearOverlays();
      let params = {

      };
      //查询电梯或区域
      const {data:elevator} = await proxy.$api.monitor.getElevatorByArea(params);
      state.elevatorData = elevator.map(item=>{
              return {"id":item.id,"gpsLat":item.lat,"gpsLng":item.lng};
            });

      var myIcon = new BMapGL.Icon(gridImg, new BMapGL.Size(36, 36));
      // setEleData(elevator[0]); // 默认设置第一台电梯
      elevator.map((ele) => {
        var marker = new BMapGL.Marker(new BMapGL.Point(ele.lng, ele.lat), {
          icon: myIcon,
        });
        state.jlMap.addOverlay(marker);
        // 跳动的动画
        marker.setAnimation("BMAP_ANIMATION_BOUNCE");
        var _iw = createInfoWindow(ele);
        marker.addEventListener("click", function () {
          state.jlMap.openInfoWindow(_iw);
          // setEleData(ele);
        });
      });
      state.jlMap.setViewport(data);
      
        
        
        

        //查询
    };

    const setEleData = (ele) => {
      state.orderColumns[1].isUpdate = true;
      state.orderColumns[1].value = state.orderData.enumber = ele.number;
      state.orderColumns[2].isUpdate = true;
      state.orderColumns[2].value = ele.address;
      state.orderColumns[3].isUpdate = true;
      state.orderColumns[3].value = ele.elevator_type;
      state.orderData.elevatorId = ele.id;
      state.squareArea.message = ele.project_name + t("checkYourAPP");
    };

    const createInfoWindow = (ele) => {
      var opts = {
        width: 400,
        height: 100,
        title:
          t("i18n.number") +
          "：<a class='eleNumber' data-sid=" +
          ele.id +
          ">" +
          ele.number +
          "</a>" +
          " | " +
          ele.address,
        enableMessage: false,
      };
      var sContent =
        "<button type='button' id='rescueButton' data-sid=" +
        ele.id +
        " class='rescueButton'>" +
        t("i18n.gridRescue") +
        "</button><button type='button' id='choosePersonBtn' data-sid=" +
        ele.id +
        " class='choosePersonBtn'>" +
        t("i18n.choosePerson") +
        "</button><button type='button' id='cloudPushButton' data-sid=" +
        ele.id +
        " class='cloudPushButton'>" +
        t("i18n.cloudPush") +
        "</button>";
      var infoWindow = new BMapGL.InfoWindow(sContent, opts);
      var pt = new BMapGL.Point(ele.lng, ele.lat);
      state.jlMap.openInfoWindow(infoWindow, pt);
      if (!infoWindow.isOpen()) {
        //如果没有打开，则监听打开事件，获取按钮，添加事件
        infoWindow.addEventListener("open", function () {
          infoWindowOnClick("eleNumber", 1);
          infoWindowOnClick("rescueButton", 2);
          infoWindowOnClick("cloudPushButton", 3);
          infoWindowOnClick("choosePersonBtn", 4);
        });
      } else {
        //如果已经打开，直接获取按钮，添加事件
        infoWindowOnClick("eleNumber", 1);
        infoWindowOnClick("rescueButton", 2);
        infoWindowOnClick("cloudPushButton", 3);
        infoWindowOnClick("choosePersonBtn", 4);
      }
      return infoWindow;
    };

    const infoWindowOnClick = (name, type) => {
      var btn = document.getElementsByClassName(name);
      for (var i = 0; i < btn.length; i++) {
        btn[i].onclick = function (e) {
          let id = e.target.dataset.sid;
          type === 1
            ? enterDetail(id)
            : type === 2
            ? NetworkRescue(id)
            : type === 3
            ? cloudPush(id)
            : showChoosePersonMenu(id);
        };
      }
    };

    const cloudPush = async (id) => {
      // 云推送
      getBounds();
      await proxy.$api.rescue.cueCloud(id, state.squareArea);
      proxy.$defined.tip(t("i18n.sendApp"), "success");
      setTimeout(() => {
        location.reload();
      }, 500);
    };

    const NetworkRescue = async (id) => {
      // 网络救援
      getBounds();
      const { data } = await proxy.$api.rescue.cueLocation(
        id,
        state.squareArea
      );
      var elePoint = new BMapGL.Point(data.elevator.lng, data.elevator.lat);
      if (data.personnel.length == 0) {
        // 地图可视范围没有维保人员
        proxy.$defined.tip(t("i18n.noPersonFind"));
        if (state.jlMap.getZoom() > 10) {
          state.jlMap.centerAndZoom(elePoint, state.jlMap.getZoom() - 2);
          setTimeout(() => {
            NetworkRescue(id);
          }, 1000);
        }
      } else {
        data.personnel.map((man) => {
          var myIcon = new BMapGL.Icon(carImg, new BMapGL.Size(23, 23));
          var marker = new BMapGL.Marker(new BMapGL.Point(man.lng, man.lat), {
            icon: myIcon,
          });
          state.jlMap.addOverlay(marker);
          var label = new BMapGL.Label(man.name + " | " + man.telephone, {
            offset: new BMapGL.Size(20, -10),
          });
          label.setStyle(state.style);
          marker.setLabel(label);
        });
        state.personnel = data.personnel;
        proxy.$defined.tip("请选择维保人员进行网格救援", "warning");
      }
    };

    const setMaintenancePerson = (man) => {
      // 网格救援设置维保人员
      state.orderColumns[7].isUpdate = true;
      state.orderColumns[7].value = man.name;
      state.orderColumns[8].isUpdate = true;
      state.orderColumns[8].value = man.telephone;
      state.orderData.maintenancePersonnelId = man.id;
      state.dialogOrderVisible = true;
    };

    const showChoosePersonMenu = async (id) => {
      // 选择人员
      state.dialogFormVisible = true;
      const { data } = await proxy.$api.rescue.otherMan({
        name: "",
        elevatorId: id,
      });
      state.columns[0].data = data;
    };

    const getBounds = () => {
      //获取可视区域
      var bs = state.jlMap.getBounds();
      var southwest = bs.getSouthWest();
      var northeast = bs.getNorthEast();
      state.squareArea.leftLng = southwest.lng;
      state.squareArea.rightLng = northeast.lng;
      state.squareArea.belowLat = southwest.lat;
      state.squareArea.upwardLat = northeast.lat;
    };

    const onSubmit = async (row) => {
      await proxy.$api.rescue.cuePerson(
        row.otherStaff,
        state.orderData.elevatorId
      );
      state.orderColumns[7].isUpdate = true;
      state.orderColumns[7].value = row.otherStaff;
      state.orderData.maintenancePersonnelId = row.otherStaff;
      state.dialogOrderVisible = true;
    };

    const resetForm = () => {
      state.dialogFormVisible = false;
      state.dialogOrderVisible = false;
    };

    const sendToMaintenance = async (val) => {
      state.orderData.actionType = 1;
      state.orderData.billModel = 40;
      for (var key in val) {
        state.orderData[key] = val[key];
      }
      await proxy.$api.maintenance.addBillOne(state.orderData);
      proxy.$defined.tip(t("i18n.workBillPush", "success"));
      location.reload();
    };

    onMounted(() => {
      initMap();
      if (route.query.enumber) {
        state.search = route.query.enumber;
        locateRescueElevator();
      } else {
        initData();
      }
    });

    return {
      ...toRefs(state),
      handleCurrentChange,
      selectElevator,
      enterDetail,
      locateRescueElevator,
      locatePoint,
      onSubmit,
      resetForm,
      setMaintenancePerson,
      sendToMaintenance,
    };
  },
};
</script>

<style lang="scss">
#mapView {
  .el-card {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    .el-card__header {
      padding: 5px 0;
      text-align: center;
      background: $blue_color;
      color: $white;
    }
    .el-card__body {
      padding: 0;
      max-height: 2 * $width;
      overflow-y: auto;
      .item {
        padding: 5px 20px;
        border-bottom: 1px solid $border_color;
        cursor: pointer;
        &:last-child {
          border: none;
        }
        span {
          margin-left: 20px;
        }
      }
    }
  }
  .el-input {
    position: absolute;
    top: 15px;
    left: 60px;
    z-index: 2;
    width: 3 * $width;
    border-radius: 10px;
    box-shadow: 0px 3px 10px 0px rgba(30, 111, 72, 0.35);
    input {
      border: none;
    }
    .el-input-group__append {
      background: $blue_color;
      color: $white;
      font-size: $font + 6;
      font-weight: bold;
    }
  }
  #rescueButton,
  #cloudPushButton,
  #choosePersonBtn {
    border: none;
    background: $blue_color;
    color: $white;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 10px 10px 0 0;
  }
  .el-tabs {
    position: absolute;
    width: 3 * $width;
    z-index: 99;
    top: 60px;
    background: $white;
    left: 60px;
    font-size: $font - 2;
    box-shadow: 0px 3px 10px 0px rgba(30, 111, 72, 0.35);
    .el-tabs__content {
      padding: 0;
    }
    .el-tabs__content {
      max-height: 300px;
    }
    .list {
      padding: 5px;
      max-height: 250px;
      overflow-y: auto;
      .index,
      .item {
        display: inline-block;
        vertical-align: top;
      }
      .active {
        background: rgba(204, 204, 204, 0.5);
        color: #333;
      }
      > div {
        padding: 10px;
        cursor: pointer;
      }
      .item {
        padding-left: 10px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #mapView {
    .card-container {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      .el-card {
        right: unset;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .el-card__body {
          max-height: 400px;
          overflow: auto;
        }
      }
    }
  }
}
</style>
